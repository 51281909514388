import styled from "styled-components";

import BackgroundNextEvent from "../../assets/clubmed/background.svg";

export const Container = styled.section`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 93px;
  background-image: url(${BackgroundNextEvent});
  background-repeat: no-repeat;
  display: flex;
  height: 481px;
  margin-bottom: 47px;
  background-color: #151515;

  @media (max-width: 730px) {
    background-size: cover;
    background-position: center;
    height: 100%;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 8%;
  display: flex;

  @media (max-width: 730px) {
    flex-direction: column;
    margin: 0 5%;
  }
`;

export const NextEventOne = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 730px) {
    width: 100%;
    align-items: center;
  }

  .next_event {
    color: white;

    @media (max-width: 730px) {
      margin-bottom: 35px;
      justify-content: center;
    }
  }
`;

export const NextEventTwo = styled.div`
  height: auto;
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 730px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const DateEdition = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    position: absolute;
    width: 150px;
    left: 250px;
    top: 30px;

    @media (max-width: 730px) {
      top: -5px;
      left: 90px;
      width: 50px;
    }
  }

  @media (max-width: 730px) {
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 41px;
  }
`;

export const Month = styled.label`
  font-family: "GothamPro", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 34px;
  color: #787878;

  @media (max-width: 730px) {
    font-size: 47px;
  }
`;

export const Date = styled.div`
  font-family: "GothamPro", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 48px;

  @media (max-width: 730px) {
    font-size: 15px;
  }
`;

export const Slogan = styled.img`
  padding-top: 66px;
  width: 100%;
  height: 100%;

  @media (max-width: 730px) {
    display: none;
  }
`;

export const SloganMobile = styled.img`
  display: none;
  width: 290px;
  height: 70px;
  margin-bottom: 41px;

  @media (max-width: 730px) {
    display: flex;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;

  @media (max-width: 730px) {
    display: none;
  }
`;

export const LogoMobile = styled.img`
  display: none;
  margin-bottom: 42px;

  @media (max-width: 730px) {
    display: flex;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  max-width: 356px;

  @media (max-width: 730px) {
    display: none;
  }
`;

export const ContainerButtonTwo = styled.div`
  display: none;
  width: 100%;
  max-width: 356px;

  @media (max-width: 730px) {
    display: flex;
  }
`;
