import styled from "styled-components";

import Background from "../../assets/landing_pages/background.svg";
import BackgroundMobile from "../../assets/landing_pages/background_mobile.svg";

export const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    height: 100%;
  }
`;

export const Content = styled.section`
  width: 100%;
  max-width: 960px;
  margin-top: 30px;

  @media (max-width: 990px) {
    padding: 10px 10px;
  }
`;

export const Header = styled.header`
  margin-bottom: 63px;

  @media (max-width: 770px) {
    height: auto;
    padding-bottom: 50px;
  }

  @media (max-width: 475px) {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }
`;

export const ContentTitle = styled.div`
  margin-bottom: 77px;
  display: flex;
  flex-direction: column;

  @media (max-width: 770px) {
    margin-bottom: 50px;
  }
`;

export const Logos = styled.figure`
  display: flex;
  align-items: center;
  gap: 35px;

  @media (max-width: 475px) {
    gap: 10px;
  }
`;

export const Logo = styled.img`
  &:nth-child(1) {
    width: 156px;
    height: 77px;
  }

  &:nth-child(2) {
    width: 145px;
    height: 87px;
  }

  @media (max-width: 475px) {
    &:nth-child(1) {
      width: 113px;
      height: 56px;
    }

    &:nth-child(2) {
      width: 105px;
      height: 63px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "GothamPro";
  margin-bottom: 31px;
  color: white;

  & :nth-child(1) {
    font-size: 20px;
  }

  & :nth-child(2) {
    font-size: 35px;
    font-weight: 900;

    @media (max-width: 498px) {
      font-size: 20px;
    }
  }
`;

export const ContentDescription = styled.article`
  font-size: 17px;
  color: white;
  font-family: "GothamPro";
  font-weight: 300;
`;

export const ContentForm = styled.div`
  font-family: "GothamPro";
  display: flex;
  flex-direction: column;
`;

export const TitleForm = styled.label`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 54px;
  color: white;

  @media (max-width: 475px) {
    margin-bottom: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  button {
    margin: 0 auto;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Warning = styled.div`
  width: 100%;
  font-size: 15px;
  font-family: "GothamPro";
  color: #151515;

  & :nth-child(1) {
    font-weight: bold;
  }
  & :nth-child(3) {
    font-weight: bold;
  }
`;
