import { FaWhatsapp } from "react-icons/fa";
import { Container, Message, WhatsAppLink } from "./styles";

const ContainerDisabled = () => {
  return (
    <Container>
      <Message>Vagas esgotadas!</Message>
      <WhatsAppLink href="https://wa.me/5511915535389" target="_blank">
        Entre em contato pelo WhatsApp para se cadastrar na fila de espera
        <FaWhatsapp size={24} style={{ marginRight: 8 }} />
      </WhatsAppLink>
    </Container>
  );
};

export default ContainerDisabled;
