import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { TextField } from "@mui/material";

import LoadingForm from "../../components/loadingForm";
import Button from "../../components/button";
import ContainerDisabled from "../../components/containerDisabled";

import {
  Container,
  ContainerInformations,
  Content,
  ContentInfos,
  Form,
  Informations,
  Logo,
  Logos,
} from "./styles";

import LogoCaioba from "../../assets/landing_pages/logo.svg";
import LogoVockan from "../../assets/landing_pages/logo_vockan.svg";

const FatherSonChallenge = () => {
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const [available, setAvailabe] = useState(false);

  const checkLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const dataForm = {
      ...formData,
      eventName: "Desafio Pai e Filho",
    };

    try {
      const response = await fetch(
        "https://api.caiobasc.com.br/landing-page/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataForm),
        }
      );

      const data = await response.json();

      if (data.success === false) {
        setLoading(false);
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(() => {
          window.location.reload();
        });

        return;
      }

      setLoading(false);

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: `Erro ao enviar formulário: ${error}`,
        html: "Não foi possível enviar o formulário",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const checkSlots = async () => {
    setLoading(true);

    try {
      const response = await fetch("https://api.caiobasc.com.br/check-slots", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventName: "Desafio Pai e Filho",
        }),
      });

      const data = await response.json();

      if (data.registration_closed === false) {
        setAvailabe(true);
      }
    } catch (error) {
      console.log("Erro:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSlots();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}

      <ContainerInformations>
        <Informations>
          <ContentInfos>
            <label>
              Inscrição para o <br />
              <span>DESAFIO PAI E FILHO</span>
            </label>
            <Logos>
              <Logo src={LogoCaioba} alt="logo_caioba" />
              <Logo src={LogoVockan} alt="logo_evoluir" />
            </Logos>
            <label>
              <strong>Data:</strong> Sábado - 05/04
            </label>
            <label>
              <strong>Horário:</strong> 17h00
            </label>
            <label>
              A Vockan, nossa parceira, preparou um desafio para você e seu
              filho criarem ainda mais memórias juntos!
            </label>
          </ContentInfos>
        </Informations>
        <Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {available === false ? (
              <ContainerDisabled />
            ) : (
              <>
                <Controller
                  control={control}
                  name="nameFather"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      label="Nome do pai"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="nameSon"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      label="Nome do filho"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="email"
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Email"
                      required
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 2 }}
                      type="email"
                    />
                  )}
                />

                <Button type="submit" backgroundColor="#1E2C52" width={300}>
                  CONFIRMAR
                </Button>
              </>
            )}
          </Form>
        </Content>
      </ContainerInformations>
    </Container>
  );
};

export default FatherSonChallenge;
