import styled from "styled-components";

import BackgroundDesktop from "../../assets/clubmed/background_desktop.jpg";
import BackgroundTablet from "../../assets/clubmed/background_tablet.jpg";
import BackgroundMobile from "../../assets/clubmed/background_mobile.jpg";

export const Container = styled.section`
  width: 100%;
  height: 599px;
  display: flex;
  background-color: #151515;
  background-image: url(${BackgroundDesktop});
  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 1000px) {
    background-image: url(${BackgroundTablet});
    background-size: cover;
  }

  @media (max-width: 500px) {
    background-position: bottom;
    background-size: contain;
    background-image: url(${BackgroundMobile});
    height: 100%;
    padding-bottom: 50%;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 8%;
  padding: 59px 0 97px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 5%;
  }

  @media (max-width: 500px) {
    align-items: center;
    margin: 0 5%;
  }
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 374px;
  height: auto;
  margin-bottom: 20px;
`;

export const EventDate = styled.span`
  font-size: 20px;
  color: #7d7d7d;
  font-weight: 700;
  font-family: "GothamPro", sans-serif;
  letter-spacing: 5px;
  margin-bottom: 5px;
`;

export const EventPeriod = styled.div`
  font-size: 60px;
  color: white;
  margin-bottom: 50px;
  font-family: "GothamPro", sans-serif;
  position: relative;

  img {
    position: absolute;
    width: 150px;
    left: 320px;

    @media (max-width: 530px) {
      left: 185px;
      width: 90px;
    }
  }

  @media (max-width: 530px) {
    font-size: 35px;
  }
`;

export const EventDescription = styled.span`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  font-family: "GothamPro", sans-serif;
  color: white;
  max-width: 743px;
  width: 50%;

  @media (max-width: 800px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const EventNote = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #f3b000;
  max-width: 743px;
  width: 100%;
  font-family: "GothamPro", sans-serif;

  width: 50%;

  @media (max-width: 800px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
